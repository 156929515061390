import React, { useRef, useEffect } from 'react'
import { login, isAuthenticated } from '../../../../utils/auth'
import { Pane } from 'evergreen-ui'
import StudyDetails from '../../../../components/StudyDetails'
import { useGlobalStore } from '../../../../hooks/useGlobalStore'
import { useRoleAccess } from '../../../../hooks/useRoleAccess'
import { useHandleStudiesList } from '../../../../hooks/useHandleStudiesList'
import { getRealmIdFromURL } from '../../../../utils/filters'
import { useLocation } from '@reach/router'

const StudiesPage = ({ params }) => {
  const { setRealmPath, realmId } = useGlobalStore()
  const { canEditStudy, canOpenUserDetails } = useRoleAccess()
  const studiesSectionRef = useRef(null)
  const location = useLocation()
  const { initialTabIndex, studiesSet, handleStudyDetailsClose } =
    useHandleStudiesList({ studiesSectionRef })

  useEffect(() => {
    setRealmPath('studies')
  }, [setRealmPath])

  const routeRealm = location ? Number(getRealmIdFromURL(location)) : -1

  // Realm Id that is set depending on the realm from URL and the global store
  const realRealmId =
    routeRealm === false ? -1 : routeRealm ? routeRealm : realmId

  if (!isAuthenticated()) {
    login()
    return (
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <h2>Redirecting to login...</h2>
      </Pane>
    )
  }

  return (
    <>
      <StudyDetails
        studyId={params['studyId']}
        show={true}
        handleDetailsClose={handleStudyDetailsClose}
        parentSectionRef={studiesSectionRef}
        initialTabIndex={initialTabIndex}
        studyNames={studiesSet.map(s => s.name)}
        canEdit={canEditStudy}
        canOpenUserDetails={canOpenUserDetails}
        realmId={realRealmId}
      />
    </>
  )
}

export default StudiesPage
